// todo: https://redmine.infomir.dev/issues/38233
import mediaBreakpoints from './constants/media.breakpoints.js';

const breakpointValidateMap = Object.values(mediaBreakpoints)
    .sort((itemA, itemB) => itemA - itemB)
    .reduce((accumulator, breakpoint, index, array) => {
        if ( index === 0 ) {
            accumulator[breakpoint] = width => width <= breakpoint;
        } else {
            accumulator[breakpoint] = width => array[index - 1] < width && width <= breakpoint;
        }

        return accumulator;
    }, {});

let clientWidthPrevious;


export default app => {
    const mediaQueries = {};

    const handleMediaQuery = () => {
        const {clientWidth} = document.documentElement;

        let breakpoint;

        if ( clientWidthPrevious === clientWidth ) {
            return;
        }

        clientWidthPrevious = clientWidth;

        for ( const item in breakpointValidateMap ) {
            if ( breakpointValidateMap[item](clientWidth) ) {
                breakpoint = Number(item);
            }
        }

        app.breakpoint = breakpoint;
        app.emit('media:breakpoint', breakpoint);
    };

    for ( const breakpoint in mediaBreakpoints ) {
        mediaQueries[breakpoint] = window.matchMedia(`(max-width: ${mediaBreakpoints[breakpoint]}px)`);
        mediaQueries[breakpoint].addListener(handleMediaQuery);

        handleMediaQuery();
    }
};
