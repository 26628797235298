export const REPORT_ENDPOINTS_READY = 'report:endpoints:ready';
export const REPORT_ENDPOINTS_PROGRESS = 'report:endpoints:progress';
export const REPORT_ENDPOINT_EVENTS_READY = 'report:endpointEvents:ready';
export const REPORT_EVENTS_READY = 'report:events:ready';
export const REPORT_CUSTOM_PROGRESS = 'report:custom:progress';
export const REPORT_CUSTOM_READY = 'report:custom:ready';
export const ENDPOINT_VALUE_RECALCULATE_DONE = 'endpointValue:recalculate:done';
export const ENDPOINT_EVENT_CREATED = 'endpointEvent:created';
export const ENDPOINT_EVENTS_UPDATED = 'endpointEvents:updated';
export const ENDPOINT_DELETED = 'endpoint:deleted';
export const DEVICES_ACTIVITY_UPDATED = 'devicesActivity:updated';
export const CONSUMPTION_CALCULATED = 'consumption:calculated';
export const MTX_OPERATION_CREATED = 'mtxOperation:created';
export const MTX_OPERATION_UPDATED = 'mtxOperation:updated';
export const MTX_JOB_CREATED = 'mtxJob:created';
export const MTX_JOB_UPDATED = 'mtxJob:updated';
export const MTX_TASK_CREATED = 'mtxTask:created';
export const MTX_TASK_UPDATED = 'mtxTask:updated';
export const MTX_MESSAGE_CREATED = 'mtxMessage:created';
export const MTX_MESSAGE_UPDATED = 'mtxMessage:updated';
