import Component from './component.js';
import Snackbar from './snackbar.js';

const DEFAULT_HIDE_DELAY = 15000;
const HIDE_ANIMATION_DELAY = 300;


/**
 * Base Snackbar implementation
 *
 * @constructor
 * @extends Component
 * @param {Object} [config={}] - init parameters (all inherited from the parent)
 *
 * @example
 * const SnackbarManager = require('snackbar-manager');
 * const snackbarManager = new SnackbarManager();
 *
 * document.body.appendChild(snackbarManager.$node);
 *
 * snackbarManager.show({
 *     type: snackbarManager.TYPE_ERROR,
 *     label: 'Oops!',
 *     text: 'Error loading data'
 * });
 *
 * snackbarManager.show({
 *     type: snackbarManager.TYPE_SUCCESS,
 *     delay: 5000,
 *     label: 'Well done!!',
 *     text: 'New item created.'
 * });
 */
export default class SnackbarManager extends Component {
    constructor ( config = {} ) {
        super(config);

        this.list = [];
    }

    /**
     * Create snackbar message
     *
     * @param {object} [config={}] - init parameters
     * @param {string} [config.label] - label text
     * @param {string} [config.text] - main text
     * @param {Symbol} [config.type] - snackbar type
     * @param {boolean} [config.autoHide=true] - auto hide snackbar
     * @param {number} [config.delay] - auto hide delay timeout
     */
    create ( config = {} ) {
        const snackbar = new Snackbar(config);
        const delay = config.delay || DEFAULT_HIDE_DELAY;
        const autoHide = config.autoHide === undefined ? true : !!config.autoHide;

        this.$node.appendChild(snackbar.$node);

        this.list.push(snackbar);

        // remove node after snackbar hide animation
        snackbar.once('hide', () => setTimeout(() => this.$node.removeChild(snackbar.$node), HIDE_ANIMATION_DELAY));

        if ( autoHide ) {
            setTimeout(() => {
                snackbar.hidden = true;
            }, delay);
        }

        if ( DEVELOP && config.delay && !autoHide ) {
            console.error('Auto hide disabled, delay will not be applied.');
        }
    }

    /**
     * Show error message
     *
     * @param {object} [config={}] - init parameters
     * @param {string} [config.label] - label text
     * @param {string} [config.text] - main text
     * @param {boolean} [config.autoHide=true] - auto hide snackbar
     * @param {number} [config.delay] - auto hide delay timeout
     */
    showError ( config = {} ) {
        config.type = Snackbar.TYPE_ERROR;

        this.create(config);
    }

    /**
     * Show success message
     *
     * @param {object} [config={}] - init parameters
     * @param {string} [config.label] - label text
     * @param {string} [config.text] - main text
     * @param {boolean} [config.autoHide=true] - auto hide snackbar
     * @param {number} [config.delay] - auto hide delay timeout
     */
    showSuccess ( config = {} ) {
        config.type = Snackbar.TYPE_SUCCESS;

        this.create(config);
    }

    /**
     * Show information message
     *
     * @param {object} [config={}] - init parameters
     * @param {string} [config.label] - label text
     * @param {string} [config.text] - main text
     * @param {boolean} [config.autoHide=true] - auto hide snackbar
     * @param {number} [config.delay] - auto hide delay timeout
     */
    showInformation ( config = {} ) {
        config.type = Snackbar.TYPE_INFORMATION;

        this.create(config);
    }

    /**
     * Show attention message
     *
     * @param {object} [config={}] - init parameters
     * @param {string} [config.label] - label text
     * @param {string} [config.text] - main text
     * @param {boolean} [config.autoHide=true] - auto hide snackbar
     * @param {number} [config.delay] - auto hide delay timeout
     */
    showAttention ( config = {} ) {
        config.type = Snackbar.TYPE_ATTENTION;

        this.create(config);
    }

    /**
     * Hide and remove all visible snackbars
     */
    hideAll () {
        this.list.forEach(item => (item.hidden = true));
        this.list = [];
    }

    static get name () {
        return 'snackbar-manager';
    }
}
