import Emitter from 'cjs-emitter';

import setDataAttributes from '../tools/set.data.attributes.js';


const counters = {};

/**
 * Base component implementation.
 *
 * Visual element that can handle sub-components.
 * Each component has a DOM element container $node with a set of classes:
 * "component" and some specific component class names depending on the hierarchy, for example "page".
 * Each component has a unique ID given either from $node.id or from data.id. If not given will generate automatically.
 *
 * @constructor
 * @extends Emitter
 *
 * @param {Object} [config={}] init parameters
 * @param {Array.<string>} [config.classList=[]] array of classes for "classList" property of this.$node
 * @param {Element} [config.$node] DOM element/fragment to be a component outer container
 * @param {Array.<Component>} [config.content=[]] list of child components
 * @param {Array.<string>} [config.modifiers=[]] list of class modifiers in this component
 * @param {Array.<string>} [config.modifiers=[]] list of class modifiers for component
 * @param {Object.<string, function>} [config.events={}] list of event callbacks
 * @param {boolean} [config.hidden=false] component initial visibility state flag
 * @param {boolean} [config.focusable=false] component can accept focus or not
 * @param {boolean} [config.focused=false] focused component or not
 *
 * @fires module:stb/component~Component#click
 *
 * @example
 * var component = new Component({
 *     $node: document.getElementById(id),
 *     classList: ['bootstrap', 'responsive'],
 *     events: {
 *         click: function () { ... }
 *     }
 * });
 * component.add( ... );
 * component.focus();
 */
export default class Component extends Emitter {
    constructor ( config = {} ) {
        super(config);

        /** @protected */
        this.internals = {
            hidden: false,
            focusable: false,
            focused: false
        };

        // apply custom name
        if ( config.name ) {
            this.name = config.name;
        } else {
            this.name = this.constructor.name;
        }

        // component DOM container (empty div in case nothing is given)
        this.$node = config.$node || document.createElement('div');

        // sanitize
        const modifiers = config.modifiers || [];
        const classList = [this.name, ...config.classList || []];

        // there are some block modifiers
        if ( modifiers.length ) {
            // build class names
            modifiers.forEach(modifier => classList.push(`${this.name}--${modifier}`));
        }

        // append all new classes to the existing ones
        this.$node.classList.add(...classList);

        if ( config.dataAttributes ) {
            setDataAttributes(config.$node, config.dataAttributes);
        }

        config.focusable && (this.focusable = true);

        // set focus if can and necessary
        config.focusable && config.focused && (this.focused = true);

        // visibility
        config.hidden && (this.hidden = true);

        config.content && this.add(...config.content);

        // apply all given events
        config.events && this.addListeners(config.events);

        // component activation by mouse
        this.$node.addEventListener('click', event => {
            if ( DEVELOP ) {
                if ( event.ctrlKey ) {
                    console.log(this);

                    event.preventDefault();

                    window.link = this;
                    console.log(`this component is now available in global scope as "window.link" or "${this.id}.component"`);

                    return;
                }
            }

            if ( event.button === 0 ) {
                /**
                 * Mouse click event.
                 *
                 * @event module:stb/component~Component#click
                 *
                 * @type {Object}
                 * @property {Event} event click event data
                 */
                this.events.click && this.emit('click', event);

                // apply focus if not canceled
                !event.defaultPrevented && this.internals.focusable && this.$node.focus();
            }
        });

        if ( DEVELOP ) {
            // expose constructor to global scope
            counters[this.constructor.name] = counters[this.constructor.name] || 0;
            counters[this.constructor.name]++;

            // expose inner ID to global scope
            this.$node.dataset.name = `component${this.constructor.name}${counters[this.constructor.name]}`;
            window[this.$node.dataset.name] = this;

            this.$node.addEventListener('click', event => {
                const time = Date.now();

                if ( event.ctrlKey ) {
                    event.stopPropagation();
                    window.link = this;
                    window[`c${time}`] = this;

                    console.group('component info');
                    console.log(this);
                    console.log(this.$node.getBoundingClientRect());
                    console.log('in DEVELOP mode this component is available in the global scope as "%s"', this.$node.dataset.name);
                    console.groupEnd();
                }
            });

            // expose a link
            Object.defineProperty(this.$node, 'component', {
                get () {
                    console.warn('Accessing the associated component from a DOM node is a development-only feature!');

                    return this;
                }
            });

            window[`Component${this.constructor.name}`] = this.constructor;
        }
    }

    /**
     * Add a new component as a child.
     *
     * @param {...Component} [children] - variable number of elements to append
     *
     * //@files Component#add
     *
     * @example
     * panel.add(
     *     new Button( ... ),
     *     new Button( ... )
     * );
     */
    add ( ...children ) {
        // walk through all the given elements
        children.forEach(child => {
            this.$node.appendChild(child.$node || child);
            child.parent = this;
        });
    }

    /**
     * Delete this component and clear all associated events.
     *
     * //@fires module:stb/component~Component#remove
     */
    remove () {
        const $parent = this.$node.parentNode;

        // clear DOM
        $parent && $parent.removeChild(this.$node);

        // notify listeners
        this.events.remove && this.emit('remove');

        // remove all listeners
        this.events = {};
    }

    get focusable () {
        return this.internals.focusable;
    }

    set focusable ( value ) {
        // sanitize
        value = !!value;

        // nothing has changed
        if ( this.internals.focusable === value ) {
            // console.warn('focusable: current and new values are identical', value, this);
        } else {
            const nodeClass = `${this.name}--focused`;

            // save
            this.internals.focusable = value;

            // apply
            if ( value ) {
                // has focus
                this.$node.setAttribute('tabIndex', '0');

                // prepare focus handlers
                this.internals.onfocus = event => {
                    // apply
                    this.$node.classList.add(nodeClass);
                    DEVELOP && this.$node.classList.add('develop-focused');
                    // notify listeners
                    this.events.focus && this.emit('focus', event);
                };
                this.internals.onblur = event => {
                    // apply
                    this.$node.classList.remove(nodeClass);
                    DEVELOP && this.$node.classList.remove('develop-focused');
                    // notify listeners
                    this.events.blur && this.emit('blur', event);
                };

                // add handlers
                this.$node.addEventListener('focus', this.internals.onfocus);
                this.$node.addEventListener('blur', this.internals.onblur);
            } else {
                // does not have focus
                this.$node.removeAttribute('tabIndex');

                // remove handlers
                this.$node.removeEventListener('focus', this.internals.onfocus);
                this.$node.removeEventListener('blur', this.internals.onblur);

                // clear
                delete this.internals.onfocus;
                delete this.internals.onblur;

                // blur just in case
                this.focused = false;
            }
        }
    }

    get focused () {
        return this.internals.focused;
    }

    set focused ( value ) {
        // apply
        if ( value ) {
            this.$node.focus();

            if ( document.activeElement !== this.$node ) {
                console.warn('focused: fail to focus or not focusable', value, this);
            }
        } else {
            this.$node.blur();
        }
    }

    get hidden () {
        return this.internals.hidden;
    }

    set hidden ( value ) {
        const nodeClass = `${this.name}--hidden`;

        // sanitize
        value = !!value;

        // nothing has changed
        if ( this.internals.hidden === value ) {
            // console.warn('hidden: current and new values are identical', value, this);
        } else {
            // save
            this.internals.hidden = value;

            // apply
            if ( value ) {
                // hide
                this.$node.classList.add(nodeClass);
                // notify listeners
                this.events.hide && this.emit('hide');
            } else {
                // show
                this.$node.classList.remove(nodeClass);
                // notify listeners
                this.events.show && this.emit('show');
            }
        }
    }
}

if ( DEVELOP ) {
    window.Component = Component;
}
