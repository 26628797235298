import * as serverEvents from 'server/constants/events/sse.js';
import {API_BASE_PATH_URL} from './constants/general.js';

import app from './app.js';

const eventNames = Object.values(serverEvents);
const prefix = 'sse:';

app.once('authorize', () => {
    const eventSource = new EventSource(`${API_BASE_PATH_URL}events?event=${eventNames.join('&event=')}`);

    for ( const eventName of eventNames ) {
        eventSource.addEventListener(eventName, event => app.emit(`${prefix}${event.type}`, JSON.parse(event.data)));
    }
});


export const events = Object.freeze(
    Object.fromEntries(Object.entries(serverEvents).map(pair => {
        pair[1] = `${prefix}${pair[1]}`;

        return pair;
    }))
);
