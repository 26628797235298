import recursiveFreezeObject from './recursive.freeze.object.js';


export default ( data, ids, skipObjectFreeze = false ) => {
    const map = {};

    const list = data.map(item => {
        item = skipObjectFreeze ? item : recursiveFreezeObject(item);
        map[item.id] = item;

        return item;
    });

    const constants = {
        list: skipObjectFreeze ? list : Object.freeze(list),
        map: skipObjectFreeze ? map : Object.freeze(map)
    };

    if ( ids ) {
        constants.ids = skipObjectFreeze ? ids : Object.freeze(ids);
    }

    return constants;
};
