const errors = {
    UNAUTHORIZED: {
        code: 401,
        body: {
            message: 'Authorization header required to perform this action.'
        }
    },

    NOT_FOUND: {
        code: 404,
        body: {
            message: 'Resource not found.'
        }
    },

    BAD_REQUEST: {
        code: 400,
        body: {
            message: 'Wrong parameters.'
        }
    },

    AUTH_TOKEN_WRONG_CREDENTIALS: {
        code: 400,
        body: {
            message: 'Wrong user credentials.'
        }
    },

    INVALID_DATA: {
        code: 400,
        body: {
            message: 'Parameters validation failed. Check structure/types/limitations.'
        }
    },

    DUPLICATED_ENTITY: {
        code: 400,
        body: {
            message: 'Entity with same unique field already exists.'
        }
    },

    INVALID_USER_DATA: {
        code: 400,
        body: {
            message: 'User data parameters validation failed. Check structure/types/limitations.'
        }
    },

    REFRESH_TOKEN_FAILURE: {
        code: 400,
        body: {
            message: 'Token expired.'
        }
    },

    DATABASE_READING: {
        code: 400,
        body: {
            message: 'Error reading data from database.'
        }
    },

    DATABASE_WRITING: {
        code: 400,
        body: {
            message: 'Error writing data to database.'
        }
    },

    INTERNAL_SERVER_ERROR: {
        code: 500,
        body: {
            message: 'Oops. Server encountered an unexpected condition.'
        }
    },

    ROOT_NO_CUSTOMER_ID: {
        code: 400,
        body: {
            message: 'Requests from super admin for customers resources must contain specific customerId.'
        }
    },

    LORA_FAIL_ACTIVATION: {
        code: 400,
        body: {
            message: 'CS: LoRa device was not activated.'
        }
    },

    LORA_FAIL_VALIDATION: {
        code: 400,
        body: {
            message: 'CS: LoRa device was not validated.'
        }
    },

    INVALID_METER_ID: {
        code: 400,
        body: {
            message: 'No meter with this id.'
        }
    },

    INVALID_METER_DATA: {
        code: 400,
        body: {
            message: 'Invalid meter data, check structure.'
        }
    },

    INVALID_METER_MODEL_ID: {
        code: 400,
        body: {
            message: 'No meter model with this id.'
        }
    },

    INVALID_LORA_DEVICE_MODEL_ID: {
        code: 400,
        body: {
            message: 'No LoRa device model with this id.'
        }
    },

    INVALID_FILES: {
        code: 400,
        body: {
            message: 'Invalid files data, check structure.'
        }
    },

    INVALID_LORA_DEVICE: {
        code: 400,
        body: {
            message: 'No LoRa device with this id or LoRa code.'
        }
    },

    INVALID_LORA_DEVICE_PORT: {
        code: 400,
        body: {
            message: 'This port number is not supported by this LoRa device.'
        }
    },

    INVALID_LORA_DEVICE_MODEL: {
        code: 400,
        body: {
            message: 'No LoRa device model with this code.'
        }
    },

    HAS_DEPENDENCIES: {
        code: 400,
        body: {
            message: 'This object has dependencies.'
        }
    },

    IS_FROZEN: {
        code: 400,
        body: {
            message: 'This object is frozen.'
        }
    },

    PROVIDER_TYPE_CS_NOT_FOUND: {
        code: 400,
        body: {
            message: 'Provider with type CS not found.'
        }
    },

    PROVIDER_TYPE_CS_MORE_THAN_ONE: {
        code: 400,
        body: {
            message: 'Provider with type CS more than one in system, please choose one with param "providerId"'
        }
    },

    PROVIDER_TYPE_CHIRPSTACK_4_NOT_FOUND: {
        code: 400,
        body: {
            message: 'Provider with type Chirpstack4 not found.'
        }
    },

    PROVIDER_TYPE_CHIRPSTACK_4_MORE_THAN_ONE: {
        code: 400,
        body: {
            message: 'Provider with type Chirpstack4 more than one in system, please choose one with param "providerId"'
        }
    },

    UPDATE_NOT_SUPPORTED: {
        code: 400,
        body: {
            message: 'Update this item is not supported.'
        }
    },

    NOT_SUPPORTED: {
        code: 400,
        body: {
            message: 'This action is not supported.'
        }
    },

    NOT_READY: {
        code: 400,
        body: {
            message: 'Server or data is not ready.'
        }
    },

    INVALID_VALIDATION: {
        code: 400,
        body: {
        }
    },

    METER_REQUIRED: {
        code: 400,
        body: {
            message: `body should have required property 'meter'`
        }
    },

    EXTERNAL_DEVICE_REQUIRED: {
        code: 400,
        body: {
            message: `body should have required property 'externalDevice'`
        }
    },

    EXTERNAL_DEVICE_OR_METER_REQUIRED: {
        code: 400,
        body: {
            message: `body should have required property 'externalDevice' or 'meter' for replacement action`
        }
    },

    ENDPOINT_ID_REQUIRED: {
        code: 400,
        body: {
            message: `body should have required property 'endpointId' for replacement or removal action`
        }
    },

    EXTERNAL_DEVICE_ALREADY_USED: {
        code: 400,
        body: {
            message: 'device already used with another endpoint'
        }
    },

    USE_METER_REQUIRED: {
        code: 400,
        body: {
            message: `body should have required property 'useMeter' on meter replacement`
        }
    },

    VALUE_OUT_OF_RANGE: {
        code: 400,
        body: {
            message: `value is out of range`
        }
    }
};


// duplicate error id to code in body
for ( const [errorName, errorValue] of Object.entries(errors) ) {
    errorValue.body.code = errorName;
}


export default Object.freeze(errors);
