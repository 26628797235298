import getSvg from '../tools/get.svg.js';
import Component from './component.js';

const TYPE_SUCCESS = Symbol('Snackbar TYPE_SUCCESS');
const TYPE_ERROR = Symbol('Snackbar TYPE_ERROR');
const TYPE_INFORMATION = Symbol('Snackbar TYPE_INFORMATION');
const TYPE_ATTENTION = Symbol('Snackbar TYPE_ATTENTION');

const modifiers = {
    [TYPE_INFORMATION]: 'information',
    [TYPE_ERROR]: 'error',
    [TYPE_SUCCESS]: 'success',
    [TYPE_ATTENTION]: 'attention'
};

const iconNames = {
    [TYPE_INFORMATION]: 'template-svg-mark-information',
    [TYPE_ERROR]: 'template-svg-mark-error',
    [TYPE_SUCCESS]: 'template-svg-mark-success',
    [TYPE_ATTENTION]: 'template-svg-problem'
};


/**
 * Base Snackbar implementation
 *
 * @constructor
 * @extends Component
 * @param {Object} [config={}] - init parameters
 * @param {String} [config.label] - label text
 * @param {String} [config.text] - main text
 * @param {Symbol} [config.type] - snackbar type
 * @param {Function} [config.actionHandler] - action handler
 * @param {String} [config.actionTitle] - action button title
 *
 * @example
 * const Snackbar = require('snackbar');
 * const errorSnackBar = new Snackbar({
 *     type: Snackbar.TYPE_ERROR,
 *     label: 'Oops!',
 *     text: 'Error loading data'
 * });
 */
export default class Snackbar extends Component {
    constructor ( config = {} ) {
        config.type = config.type || TYPE_INFORMATION;

        const {$node, links} = (
            <div>
                <div className="snackbar__icon">
                    {{$node: getSvg(iconNames[config.type])}}
                </div>
                <div className="snackbar__information">
                    <span className="snackbar__label" link="$label">
                        {config.label || ''}
                    </span>
                    <span className="snackbar__text" link="$text">
                        {config.text || ''}
                    </span>
                </div>
            </div>
        );

        if ( config.actionHandler ) {
            const action = (
                <div className="snackbar__button">
                    <div className="snackbar__button-title">{config.actionTitle}</div>
                </div>
            );

            action.$node.addEventListener('click', config.actionHandler);

            $node.append(action.$node);
        }

        config.$node = $node;

        config.modifiers = config.modifiers || [];
        config.modifiers.push(modifiers[config.type]);

        $node.addEventListener('click', () => (this.hidden = true));

        super(config);

        this.type = config.type;
        this.links = links;
    }

    static get name () {
        return 'snackbar';
    }

    static get TYPE_SUCCESS () {
        return TYPE_SUCCESS;
    }

    static get TYPE_ERROR () {
        return TYPE_ERROR;
    }

    static get TYPE_INFORMATION () {
        return TYPE_INFORMATION;
    }

    static get TYPE_ATTENTION () {
        return TYPE_ATTENTION;
    }

    set label ( label ) {
        this.links.$label.textContent = label;
    }

    set text ( text ) {
        this.links.$text.textContent = text;
    }
}
