export const STORAGE_KEY_APP_DATA = 'data';

export const STORAGE_KEY_APP_LANGUAGE = 'language';

export const DEFAULT_QUERY_SIZE_LIMIT = 40;

export const API_BASE_PATH = `${window.location.protocol}//${window.location.host}/api/`;

export const API_BASE_PATH_URL = `${API_BASE_PATH}v1/`;

export const DEFAULT_REPORT_FILE_EXTENSION = '.csv';
