import {STORAGE_KEY_APP_DATA} from './constants/general.js';

const storage = {
    get data () {
        return storageData;
    },

    saveData: dataObject => {
        if ( typeof dataObject !== 'object' ) {
            throw new Error('Saved data should be Object');
        }

        Object.assign(storageData, dataObject);

        localStorage.setItem(STORAGE_KEY_APP_DATA, JSON.stringify(storageData));
    }
};

let storageData = {};


export default app => {
    let result = localStorage.getItem(STORAGE_KEY_APP_DATA);

    try {
        result = JSON.parse(result);

        if ( result && typeof result === 'object' ) {
            storageData = result;
        }
    } catch ( error ) {
        console.error(error);
    }

    Object.defineProperty(app, 'storage', {
        get () {
            return storage;
        }
    });
};
