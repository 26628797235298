import Component from './component.js';


export default class TabSet extends Component {
    constructor ( config = {} ) {
        super(config);

        this.reset();
    }

    add ( ...tabs ) {
        tabs.forEach(tab => {
            // all tabs are hidden from the start
            tab.hidden = true;
            tab.parent = this;

            // apply
            this.$node.appendChild(tab.$node);
        });

        this.internals.tabs = tabs;
    }

    get data () {
        return this.internals.tabs;
    }

    get current () {
        return this.internals.current;
    }

    set current ( current ) {
        const previous = this.internals.current;

        // nothing has changed
        if ( current === previous ) {
            console.warn('current: current and new values are identical', current, this);
        } else {
            // hide current visible tab
            previous && (previous.hidden = true);

            // show new
            this.internals.current = current;
            current.hidden = false;

            // notify listeners
            this.events.switch && this.emit('switch', previous, current);
        }
    }

    reset () {
        const {tabs} = this.internals;

        this.internals.current = null;

        if ( tabs ) {
            tabs.forEach(tab => (tab.hidden = true));
        }
    }

    static get name () {
        return 'tab-set';
    }
}
