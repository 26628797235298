/**
 * Generate specific GET params for url.
 *
 * @param {object} options - config for url generator
 *
 * @example
 *
 * const prepareQuery = require('query.generator');
 *
 * console.log(prepareQuery({
 *     with: ['meters', 'users'],
 *     where: {typeId: 3,
 *         meter: {resourceTypeId: 1}
 *     },
 *     limit: 30,
 *     offset: 34
 * }));
 * // will print "?typeId=3&meter.resourceTypeId=1&offset=34&with=meters&with=users"
 */
export default options => {
    const params = [];

    let paramsString = '';

    if ( options.where ) {
        const keys = Object.keys(options.where);

        keys.forEach(key => {
            if ( options.where[key] || ['boolean', 'number'].includes(typeof options.where[key]) ) {
                if ( typeof options.where[key] === 'object' && !Array.isArray(options.where[key]) ) {
                    // complex value
                    const complexKeys = Object.keys(options.where[key]);

                    for ( const complexKey of complexKeys ) {
                        if ( Array.isArray(options.where[key][complexKey]) ) {
                            options.where[key][complexKey].forEach(value => {
                                params.push({key: `${key}.${complexKey}`, value});
                            });
                        } else if ( options.where[key][complexKey] || typeof options.where[key][complexKey] === 'boolean' ) {
                            params.push({key: `${key}.${complexKey}`, value: options.where[key][complexKey]});
                        }
                    }
                } else if ( Array.isArray(options.where[key]) ) {
                    options.where[key].forEach(value => {
                        params.push({key, value});
                    });
                } else if ( options.where[key] || ['boolean', 'number'].includes(typeof options.where[key]) ) {
                    // literal value
                    params.push({key, value: options.where[key]});
                }
            }
        });
    }

    if ( options.offset !== undefined ) {
        params.push({key: 'offset', value: options.offset});
    }

    if ( options.limit !== undefined ) {
        params.push({key: 'limit', value: options.limit});
    }

    if ( Array.isArray(options.with) ) {
        options.with.forEach(element => params.push({key: 'with', value: element}));
    }

    if ( params.length ) {
        const searchParams = new URLSearchParams();

        params.forEach(param => searchParams.append(param.key, param.value));

        paramsString = `?${searchParams.toString()}`;
    }

    return paramsString;
};
