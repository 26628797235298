/**
 * Get SVG by its ID, clone it on repeated retrievals.
 *
 * @module
 */

const store = {};


export default id => {
    if ( store[id] ) {
        return store[id].cloneNode(true);
    }

    if ( DEVELOP ) {
        // catch bad merge or inform developer to add missid file
        try {
            // eslint-disable-next-line no-unused-expressions
            document.getElementById(id).content;
        } catch ( error ) {
            console.log(`svg with id ${id} doesn't exists`, 'check it in pug/templates.pug and svg directory');
            console.log(error);
        }
    }

    return (store[id] = document.getElementById(id).content.firstChild);
};
