/**
 * Main application entry point.
 */

import {load} from 'spa-gettext';

import app from './app.js';
import './sse.js';
import mediaHandler from './media.handler.js';
import storage from './storage.js';

import isElementScrollOverflowed from './tools/is.element.scroll.overflowed.js';

import TabSet from './components/tab-set.js';
import Preloader from './components/preloader.js';
import SnackbarManager from './components/snackbar-manager.js';


app.isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;

// load localization
load({path: '/lang', name: app.config.language.slice(0, 2)}, async () => {
    const {gettext} = window;

    // override gettext to replace control character \n with <br> in localization key
    window.gettext = key => gettext(key).replace(/\n/g, '<br>');

    window.addEventListener('scroll', async () => {
        if ( tabSet.current && isElementScrollOverflowed(document.documentElement) ) {
            tabSet.current.emit('scroll:overflow');
        }
    });

    await (await import('./models/user.js')).default.init();

    app.routePaths = (await import('./routes/config.js')).routePaths;
    app.modals = (await import('./modals/index.js')).default;
    app.views = (await import('./views/index.js')).default;

    const pages = (await import('./pages/index.js')).default;

    const tabSet = new TabSet({
        $node: document.body,
        content: [...Object.values(pages), app.snackbarManager = new SnackbarManager()]
    });

    app.preloader = new Preloader();
    document.body.appendChild(app.preloader.$node);

    await import('./components/error-interceptor.js');

    app.pages = pages;
    app.pageSet = tabSet;

    app.addListeners({
        'auth:error': async () => {
            if ( app.user ) {
                await app.logout();

                return;
            }

            app.route.redirect('/auth/login');
        },

        authorize: async () => {
            // init dictionary models
            await Promise.all([
                (await import('./models/meter.type.js')).default.init(),
                (await import('./models/location.type.js')).default.init(),
                (await import('./models/brand.js')).default.init(),
                (await import('./models/lora.device.model.js')).default.init(),
                (await import('./models/provider.type.js')).default.init(),
                (await import('./models/meter.model.js')).default.init()
            ]);

            storage(app);
            mediaHandler(app);
            pages.main.init();

            (await import('./routes/index.js')).default();

            // library https://github.com/visionmedia/page.js decodes sing "+" and encoded plus sign "%2B" to " "
            // option "decodeURLComponents=false" disables decoding by this library
            // for correct decoding, https://github.com/ljharb/qs library is used, when parsing querystring
            // update instance with new routes and options
            app.route({decodeURLComponents: false});
        }
    });

    (await import('./routes/index.js')).default();
    app.route();

    if ( !app.check() ) {
        return;
    }

    if (
        window.location.pathname === app.routePaths.authPasswordNewRequest
        || window.location.pathname === app.routePaths.authPasswordNewApply
    ) {
        return;
    }

    try {
        await app.init();
    } catch ( error ) {
        console.error(error);
    }
});
