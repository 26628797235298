/**
 * Preloader/spinner/progress-bar.
 *
 * Express an unspecified amount of wait time for some operation.
 *
 * @see {@link https://material.io/develop/web/components/linear-progress/}
 *
 * @module
 */

import Component from './component.js';


/**
 * Base Preloader implementation.
 *
 * @constructor
 * @extends Component
 *
 * @param {Object} [config={}] - init parameters (all inherited from the parent)
 *
 * @example
 * const Preloader = require('preloader');
 * const preloader = new Preloader();
 *
 * document.body.appendChild(preloader.$node);
 * preloader.title = 'In Progress';
 * preloader.hidden = false;
 */
export default class Preloader extends Component {
    constructor ( config = {} ) {
        const {$node, links} = (
            <div className="preloader">
                <div className="preloader__circle"></div>
                <div link="$title" className="preloader__text">{gettext('loading')}...</div>
            </div>
        );

        if ( !('hidden' in config) ) {
            config.hidden = true;
        }

        config.$node = $node;

        super(config);

        this.links = links;
    }

    static get name () {
        return 'preloader';
    }

    set title ( value ) {
        this.links.$title.textContent = value;
    }
}
