/**
 * Global application configuration.
 * Can store run-time options, API urls, paths, execution flags and so on.
 */

import {ids as languageIds} from 'server/constants/languages.js';
import {STORAGE_KEY_APP_LANGUAGE} from './constants/general.js';


const languageIdList = Object.values(languageIds);

const isLanguageSupported = languageId => languageIdList.includes(languageId);

const resolveLanguage = () => {
    const languageFromStorage = localStorage.getItem(STORAGE_KEY_APP_LANGUAGE);

    if ( isLanguageSupported(languageFromStorage) ) {
        return languageFromStorage;
    }

    const languageFromNavigator = window.navigator.language.slice(0, 2);

    if ( isLanguageSupported(languageFromNavigator) ) {
        return languageFromNavigator;
    }

    return languageIds.EN;
};


export const language = resolveLanguage();

export default {
    language
};
