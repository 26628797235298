const recursiveFreezeObject = data => {
    if ( !data ) {
        return data;
    }

    const keys = Object.keys(data);

    keys.forEach(key => {
        if ( Array.isArray(data[key]) ) {
            data[key].forEach(item => {
                recursiveFreezeObject(item);
            });
        } else if ( typeof data[key] === 'object' ) {
            recursiveFreezeObject(data[key]);
        }
    });

    data = Object.freeze(data);

    return data;
};

export default recursiveFreezeObject;
