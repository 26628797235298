import buildConstants from '../utils/build.constants.js';


const ids = {
    EN: 'en',
    UK: 'uk',
    DE: 'de',
    RU: 'ru'
};

const data = [
    {
        id: ids.EN,
        name: 'English'
    },
    {
        id: ids.UK,
        name: 'Українська'
    },
    {
        id: ids.DE,
        name: 'Deutsch'
    },
    {
        id: ids.RU,
        name: 'Русский'
    }
];

const languages = buildConstants(data, ids);
const languageIds = languages.ids;


export const {map} = languages;

export const {list} = languages;

export {
    languages as default,
    languageIds as ids
};
